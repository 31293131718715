import { Order } from './order.model';
import { SuperModel } from './super-model.model';
import { EShipFromStoreStatus } from '@common/enums';
import { Asset } from '@common/core/models/asset.model';
import { ShipFromStoreItem } from '@common/core/models/ship-from-store-item.model';
import { IShipFromStoreOrderHistory } from '@common/core/models/ship-from-store-order.interface';

export class ShipFromStoreOrder extends SuperModel {
  customerFullName: string;
  order: Order;
  orderNumber: string;

  status: EShipFromStoreStatus;
  newAt: Date | string;
  packedAt: Date | string;
  sentToLogisticAt: Date | string;
  canceledAt: Date | string;
  shippingLabel: Asset;
  creationDate: Date;
  items: ShipFromStoreItem[];
  assignmentHistory: IShipFromStoreOrderHistory[];

  constructor(data: any = {}) {
    super(data);
    if (data.order) {
      this.order = new Order(data.order);
    }
    if (data.shippingLabel) {
      this.shippingLabel = new Asset(data.shippingLabel);
    }
    if (data.items) {
      this.items = data.items.map(item => new ShipFromStoreItem(item));
    }
  }

  get serialised() {
    return {};
  }
}
