import { SuperModel } from './super-model.model';
import { ECurrency, EOrderStatus } from '@common/enums';
import { OrderItem } from './order-item.model';
import { ReturnOrder } from './return-order.model';
import { IAddress, IOrderHistory, IPaymentInfo, IPriceModifications } from '@interfaces';

export class Order extends SuperModel {
  ordernumber: string;
  orderdate: string;
  order_customer_firstname: string;
  order_customer_lastname: string;
  orderState?: EOrderStatus;
  orderhistory: IOrderHistory[];
  paymentInfo: IPaymentInfo[];
  priceModifications: IPriceModifications[];
  deliveryCompany: any;
  deliveryStreet: string;
  deliveryCity: string;
  deliveryZip: string;
  deliveryProvince: string;
  deliveryCountry: string;
  shipped: boolean;
  pickingTipology: string;
  origin: string;
  pickingStore: {
    name: string;
    warehouse_type: string;
  };
  customerFirstname: string;
  customerLastname: string;
  customerFullName: string;
  customerEmail: string;
  customerStreet: string;
  customerCity: string;
  customerZip: string;
  customerCompany: string;
  customerProvince: string;
  customerProvinceCode: string;
  customerCountry: string;
  customerPhone: string;
  totalPrice: number;
  items: OrderItem[];
  currency: string;
  returns?: ReturnOrder[];

  constructor(data: Partial<Order> = {}) {
    super(data);

    if (data.items) {
      this.items = data.items.map(item => new OrderItem(item));
    }
    if (data.returns) {
      this.returns = data.returns.map(returnOrder => new ReturnOrder(returnOrder));
    }
    if (!data.currency) {
      this.currency = ECurrency.EUR;
    }
  }

  get customerName() {
    return `${this.customerFirstname || ''} ${this.customerLastname || ''}`;
  }

  get orderCustomerName() {
    let customerFullname = `${this.order_customer_firstname || ''} ${this.order_customer_lastname || ''}`;
    if (customerFullname && customerFullname.trim() === '') {
      customerFullname = `${this.customerFirstname || ''} ${this.customerLastname || ''}`;
    }
    return customerFullname;
  }

  get hasInvoiceAddress() {
    return this.customerStreet;
  }

  get invoiceAddress(): IAddress {
    return {
      name: this.customerName,
      company: this.customerCompany,
      street: this.customerStreet,
      city: this.customerCity,
      zip: this.customerZip,
      province: this.customerProvince,
      provinceCode: this.customerProvinceCode,
      country: this.customerCountry,
    };
  }

  get hasDeliveryAddress() {
    return this.deliveryStreet;
  }

  get deliveryAddress(): IAddress {
    return {
      name: this.customerName,
      company: this.deliveryCompany,
      street: this.deliveryStreet,
      city: this.deliveryCity,
      zip: this.deliveryZip,
      province: this.deliveryProvince,
      provinceCode: '',
      country: this.deliveryCountry,
    };
  }

  get serialised() {
    return {
      orderState: this.orderState,
    };
  }

  get paymentState() {
    if (this.paymentInfo != null && this.paymentInfo.length > 0) {
      return this.paymentInfo[0].paymentState;
    } else {
      return 'MISSING';
    }
  }
}
