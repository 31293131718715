import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@common/core/services/guards';

const routes: Routes = [
  // NOTE: do not change the order or routes
  { path: 'm', redirectTo: 'm/dashboard', pathMatch: 'full' },
  {
    path: 'm/auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'm',
    loadChildren: () => import('./modules/mobile/mobile.module').then(m => m.MobileModule),
    canActivate: [AuthGuard],
  },

  // NOTE: do not change the order or routes
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./modules/desktop/desktop.module').then(m => m.DesktopModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
