// eslint-disable-next-line max-len
import {
  BitfGraphQlPaginationApiRequestPart,
  BitfGraphQlSortApiRequestPart,
  BitGraphQlFormItemConfigApiRequestPart,
} from '@bitf/core/api-call-state/bitf-graph-ql-api-call-state';

import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfApiRequestPartsNames,
  EBitfApiSortDirection,
} from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import { WarehouseFilterApiRequestPart } from './warehouse-filter.api-request-part';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_RETURNS,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_PICKUP_IN_STORE,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_PICKUP_IN_STORE,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'orderNumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_COUNT_SHIP_FROM_STORE,
    requestParts: [
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_RETURNS,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'ordernumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_SHIP_FROM_STORE,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'ordernumber', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.PICKUP_IN_STORE_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'pickedAt', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.SHIP_FROM_STORE_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SHIP_FROM_STORE_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'newAt', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SHIP_FROM_STORE_STATUS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new WarehouseFilterApiRequestPart('store__id'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  },
  {
    apiCallStateName: EApiCallStateNames.ORDERS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'orderdate', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_STATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_PICKING_TYPOLOGY_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ORDER_TOTAL_PRICE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.RETURNS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_NUMBER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_ORDER_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_REFUND_TYPE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_STATUS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.RETURN_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'requestTime', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new WarehouseFilterApiRequestPart('return_store'),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.TICKETS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'o_id', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ORDER_N_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_STATUS_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.TICKET_ITEMS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 999 },
        options: { pageIndexOffset: 1 },
      }),
      new BitGraphQlFormItemConfigApiRequestPart(),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.TICKET_ITEM_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'o_id', direction: EBitfApiSortDirection.DESC },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
