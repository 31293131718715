import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '@env/environment';

export const NAVIGATION_MENU = [
  {
    title: bitfToTranslate('COMMON.LABEL.DASHBOARD'),
    icon: '',
    routerLink: ['/dashboard'],
    svgIcon: 'dashboard-icon',
    iconSize: '40px',
    isModuleEnabled: true,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.PICKUP_IN_STORE'),
    icon: '',
    routerLink: ['/pickup-in-store'],
    svgIcon: 'click-and-collect-icon',
    iconSize: '40px',
    isModuleEnabled: environment.clickCollect.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.RETURNS'),
    icon: '',
    routerLink: ['/returns'],
    svgIcon: 'return-icon',
    iconSize: '40px',
    isModuleEnabled: environment.returns.isModuleEnabled,
  },
  {
    title: bitfToTranslate('COMMON.LABEL.SHIP_FROM_STORE'),
    icon: '',
    routerLink: ['/ship-from-store'],
    svgIcon: 'ship-from-store-icon',
    iconSize: '40px',
    isModuleEnabled: environment.shipFromStore.isModuleEnabled,
  },
];

export function getEnabledNavMenu() {
  return NAVIGATION_MENU.filter(obj => obj.isModuleEnabled === true);
}
