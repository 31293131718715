import { BitfGraphQlRequestMapper } from '../graph-ql-parser/bitf-graph-ql-request.mapper';
import { EBitfGraphQlParsers } from '@enums';

export class BitfPimcoreRequestMapper extends BitfGraphQlRequestMapper {
  name = EBitfGraphQlParsers.BITF_PIMCORE_PARSER;

  constructor() {
    super();
  }

  protected mapFiltersVariables({ mappedVariables, filterAccumulator }) {
    mappedVariables.filter = `{"$and": [${filterAccumulator}]}`;
  }

  protected mapSortingVariables({ mappedVariables, sorting }) {
    Object.assign(mappedVariables, {
      sortBy: sorting.map(sort => sort.property).reverse(),
      sortOrder: sorting.map(sort => sort.direction).reverse(),
    });
  }

  protected mapPaginationVariables({ mappedVariables, size, page }) {
    Object.assign(mappedVariables, {
      first: size,
      after: size * (page - 1),
    });
  }

  protected mapPaginationVariablesByOffset({ mappedVariables, start, limit }) {
    Object.assign(mappedVariables, {
      first: limit,
      after: start,
    });
  }
}
