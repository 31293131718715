import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export enum EOrderStatus {
  NEW = 'new',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  SHIPPED = 'shipped',
  COMMITTED = 'committed',
  PAYMENT_PENDING = 'paymentPending',
  ERROR = 'error',
  OUT_OF_STOCK = 'outofstock',
}
bitfToTranslate(`COMMON.STATUSES.NEW`);
bitfToTranslate(`COMMON.STATUSES.CLOSED`);
bitfToTranslate(`COMMON.STATUSES.COMPLETED`);
bitfToTranslate(`COMMON.STATUSES.IN_PROGRESS`);
bitfToTranslate(`COMMON.STATUSES.SHIPPED`);
bitfToTranslate(`COMMON.STATUSES.COMMITTED`);
bitfToTranslate(`COMMON.STATUSES.PAYMENTPENDING`);
bitfToTranslate(`COMMON.STATUSES.ERROR`);
bitfToTranslate(`COMMON.STATUSES.OUTOFSTOCK`);

export enum EReturnStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DENY = 'deny',
  RETURNEDINSTORE = 'returnedInStore',
  SENTTOLOGISTIC = 'sentToLogistic',
  RETURNED = 'returned',
  CLOSED = 'closed',
}
bitfToTranslate(`COMMON.STATUSES.PENDING`);
bitfToTranslate(`COMMON.STATUSES.ACCEPTED`);
bitfToTranslate(`COMMON.STATUSES.DENY`);
bitfToTranslate(`COMMON.STATUSES.RETURNED`);
bitfToTranslate(`COMMON.STATUSES.RETURNEDINSTORE`);
bitfToTranslate(`COMMON.STATUSES.SENTTOLOGISTIC`);

export enum ETicketStatus {
  NEW = 'new',
  IN_PROGRESS = 'pending',
  WAITING_FOR_DELIVERY = 'waiting',
  READY_FOR_SHIPMENT = 'shipment_ready',
  PACK_PREPARED = 'pack_prepared',
  SHIPMENT_REQUESTED = 'shipment_requested',
  GOODS_RECEIVED = 'received',
  READY_FOR_THE_CUSTOMER = 'ready',
  PACK_DELIVERED = 'delivered',
  SHIPPED = 'shipped',
  ERROR = 'error',
  CANCELED = 'voided',
}

bitfToTranslate(`COMMON.STATUSES.WAITING`);
bitfToTranslate(`COMMON.STATUSES.SHIPMENT_READY`);
bitfToTranslate(`COMMON.STATUSES.PACK_PREPARED`);
bitfToTranslate(`COMMON.STATUSES.SHIPMENT_REQUESTED`);
bitfToTranslate(`COMMON.STATUSES.RECEIVED`);
bitfToTranslate(`COMMON.STATUSES.READY`);
bitfToTranslate(`COMMON.STATUSES.DELIVERED`);
bitfToTranslate(`COMMON.STATUSES.VOIDED`);

export enum ETicketItemStatus {
  NEW = 'new',
  ERROR = 'error',
  CANCELED = 'voided',
  PRODUCT_UNAVAILABLE = 'unavailable',
  PRODUCT_AVAILABLE = 'available',
  RESERVED_GOODS = 'reserved',
  PACK_RECEIVED = 'received',
  SHIPMENT_REQUESTED = 'shipment_requested',
  PACK_SHIPPED_TO_CUSTOMER = 'customer_shipped',
  PACK_SHIPPED = 'shipped',
  PACK_READY = 'ready',
  DELIVERED_TO_THE_CUSTOMER = 'delivered',
  PICKING_PRESENT = 'picking_present',
  PICKING_MISSING = 'picking_missing',
  PACKING_MISSING = 'packing_missing',
}
bitfToTranslate(`COMMON.STATUSES.AVAILABLE`);
bitfToTranslate(`COMMON.STATUSES.UNAVAILABLE`);
bitfToTranslate(`COMMON.STATUSES.RESERVED`);
bitfToTranslate(`COMMON.STATUSES.CUSTOMER_SHIPPED`);
bitfToTranslate(`COMMON.STATUSES.PICKING_MISSING`);
bitfToTranslate(`COMMON.STATUSES.PACKING_MISSING`);

export type TStatus = typeof EOrderStatus | typeof EReturnStatus | typeof ETicketStatus;

export enum EStatusColor {
  GREY = 'grey',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  ORANGE = 'orange',
}
