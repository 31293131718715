import { Asset } from '@web/core/models';
import { SuperModel } from './super-model.model';
import { environment } from '@env/environment';

export class Product extends SuperModel {
  colorCode: string;
  description: string;
  detailImageName: string;
  lavaggio: string;
  lineCode: string;
  lookImageName: string;
  materialCode: string;
  modello: string;
  name: string;
  season: string;
  seasonCode: string;
  sizeCode: string;
  sku: string;
  images: Asset[];
  defaultImage: Asset;
  color: string;
  size: string;
  // From metadata
  requestedQuantity?: number;
  parent?: Product;

  constructor({
    element,
    metadata,
  }: {
    element: Partial<Product>;
    metadata?: { name: string; value: any }[];
  }) {
    super(element);

    if (element.images && element.images.length) {
      this.images = element.images.map(asset => new Asset((asset as any).image || asset));
      this.defaultImage = this.images[0];
    }

    // Check Default Images -> Get It from parent
    if (this.defaultImage == null && element.parent?.images != null && element.parent?.images.length > 0) {
      const parentImages = element.parent.images.map(asset => new Asset((asset as any).image || asset));
      this.defaultImage = parentImages[0];
    }

    if (metadata) {
      // NOTE: normalize metadata properties
      this.requestedQuantity = Number(metadata.find(meta => meta.name === 'qty_requested')?.value || 0);
    }

    this.mapSize(element);
    this.mapColor(element);
  }

  get serialised() {
    return {};
  }

  mapSize(data) {
    const {
      apiProperties: { size },
    } = environment;

    if (typeof data[size] === 'object' && data[size] !== null) {
      const sizeAttr = Object.values(data[size])[0];
      if (sizeAttr !== null) {
        this.size = sizeAttr.toString();
      } else {
        this.size = '';
      }
    } else {
      this.size = data[size];
    }
  }

  mapColor(data) {
    const {
      apiProperties: { color },
    } = environment;

    if (typeof data[color] === 'object' && data[color] !== null) {
      const colorAttr = Object.values(data[color])[0];
      if (colorAttr !== null) {
        this.color = colorAttr.toString();
      } else {
        this.color = '';
      }
    } else {
      this.color = data[color];
    }
  }
}
